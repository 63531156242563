import React from "react";

import "./assets/css/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import appstore from "./assets/img/App Store.png";
import playstore from "./assets/img/Play Store.png";
import logo from "./assets/img/logo.svg";

function App() {
  return (
    <div className="App">
      <header className="section-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <img className="logo" src={logo} alt={"asd"} />
            </div>
            <div className="col-sm-6">
              <nav>
                <ul className="list-inline">
                  <li>
                    <a href="">Deals</a>
                  </li>
                  <li>
                    <a href="">About Us</a>
                  </li>

                  <li>
                    <a href="">Login</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="row header-content">
            <div className="col-md-offset-1 col-md-4 col-sm-6 hidden-xs">
              <img
                className="hand"
                src={require("./assets/img/hand.png")}
                alt="phone"
              />
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <h1>
                PaidUp enables Small Businesses to Crowdfund from their own
                customers
              </h1>

              <a style={{margin: 10}}
                href="https://play.google.com/store/apps/details?id=ph.paidup"
                className="app-link"
              >
                <img src={playstore} alt="Get it on Google Play" />
              </a>
         <a style={{margin: 10}}
                href="https://itunes.apple.com/us/app/paidup-phil/id1053281996?ls=1&mt=8"
                className="app-link"
              >
                <img src={appstore} alt="Available on the App Store" />
              </a>

              <div className="visible-xs" style={{ height: 50 }}>
                &nbsp;
              </div>

              <div
                style={{ marginTop: 10 }}
                className="fb-page"
                data-href="https://www.facebook.com/paidupPH/"
                data-width="400"
                data-tabs=""
                data-small-header="true"
                data-adapt-container-width="true"
                data-hide-cover="true"
                data-show-facepile="true"
              >
                <blockquote
                  cite="https://www.facebook.com/paidupPH/"
                  className="fb-xfbml-parse-ignore"
                >
                  <a href="https://www.facebook.com/paidupPH/">PaidUp</a>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
        <div className="block-angle"></div>
      </header>

      <section className="section-partners">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <a href="">
                {" "}
                <h4 className="section-title">
                  OUR PARTNER ESTABLISHMENTS
                </h4>{" "}
              </a>

              <div className="sm-orange center">
                <hr />
              </div>

              <div id="partners-carousel" className="">
                <div className="partners-item item">
                  <div className="partner-list active">
                    <img
                      src={require("./assets/img/partners/Browns Gourmet.jpg")}
                      alt="Partner"
                    />
                    <div className="title18 partner-caption">
                      Browns Gourmet
                    </div>
                  </div>
                  <div className="partner-list">
                    <img
                      src={require("./assets/img/partners/Infinitea.jpg")}
                      alt="Partner"
                    />
                    <div className="title18 partner-caption">Infinitea</div>
                  </div>
                  <div className="partner-list">
                    <img
                      src={require("./assets/img/partners/Dulcelin Gourmet.jpg")}
                      alt="Partner"
                    />
                    <div className="title18 partner-caption">
                      Dulcelin Gourmet
                    </div>
                  </div>
                  <div className="partner-list">
                    <img
                      src={require("./assets/img/partners/Xocolat.jpg")}
                      alt="Partner"
                    />
                    <div className="title18 partner-caption">Xocolat</div>
                  </div>
                  <div className="partner-list">
                    <img
                      src={require("./assets/img/partners/jambajuice.jpg")}
                      alt="Partner"
                    />
                    <div className="title18 partner-caption">Jamba Juice</div>
                  </div>
                  <div className="partner-list">
                    <img
                      src={require("./assets/img/partners/CoCo.jpg")}
                      alt="Partner"
                    />
                    <div className="title18 partner-caption">CoCo Milk Tea</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-howitworks background-green">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h4 className="section-title">HOW IT WORKS?</h4>
              <div style={{ width: 250 }} className="sm-orange center white">
                <hr />

                <Slider
                  centerPadding={160}
                  autoplay
                  style={{
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  settings={{
                    dots: true,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }}
                >
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                    className="item active-elem"
                  >
                    <img
                      alt={""}
                      src={require("./assets/img/how/02-deal.jpg")}
                      title="Choose Offer"
                    />
                  </div>
                  <div className="item active-elem">
                    <img
                      alt={""}
                      src={require("./assets/img/how/03-cashier.png")}
                      title="Load Up"
                    />
                  </div>
                  <div className="item active-elem">
                    <img
                      alt={""}
                      src={require("./assets/img/how/04-menu.jpg")}
                      title="Order from Menu"
                    />
                  </div>
                  <div className="item active-elem">
                    <img
                      alt={""}
                      src={require("./assets/img/how/05-landing page.png")}
                      title="Open App"
                    />
                  </div>
                  <div className="item active-elem">
                    <img
                      alt={""}
                      src={require("./assets/img/how/01-restaurant.jpg")}
                      title="Choose Store"
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-dashboard background-green">
        <div className="container">
          <div className="row">
            <div className="col-sm-7">
              <img
                alt={""}
                className="merchant"
                src={require("./assets/img/merchant.png")}
              />
            </div>
            <div className="col-sm-5">
              <h4 className="section-title">MERCHANT DASHBOARD</h4>
              <div className="sm-orange white">
                <hr />
              </div>
              <p>
                Merchant dashboard enables enterprises to learn more about their
                customers. It also provides in- depth revenue and data
                analytics.
              </p>
              <a className="btn btn-lg">Learn More</a>
            </div>
          </div>
          <div style={{ display: "flex", margin: 30 }}>
            <div
              className="col-md-4"
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img
                src={require("./assets/img/icons/white/monitor.svg")}
                alt=""
              />
              <p style={{ marginLeft: 25 }}>Monitor Orders and Payments</p>
            </div>

            <div
              className="col-md-4"
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img
                src={require("./assets/img/icons/white/customer.svg")}
                alt=""
              />
              <p style={{ marginLeft: 25 }}>Customer Intel and Analytics</p>
            </div>
            <div
              className="col-md-4"
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img src={require("./assets/img/icons/white/pos.svg")} alt="" />
              <p style={{ marginLeft: 25 }}>
                Integrated P.O.S
                <br />
                System
              </p>
            </div>
          </div>

          <div style={{ display: "flex", margin: 30 }}>
            <div
              className="col-md-6"
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img
                src={require("./assets/img/icons/white/marketing.svg")}
                alt=""
              />
              <p style={{ marginLeft: 25 }}>
                Direct Marketing Channel for Loans
              </p>
            </div>
            <div
              className="col-md-6"
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img
                src={require("./assets/img/icons/white/repayment.svg")}
                alt=""
              />
              <p style={{ marginLeft: 25 }}>Automatic Loan Repayment</p>
            </div>
          </div>
        </div>
      </section>

      <section className="section-subscribe background-green">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="subscribe-content">
                <h6 className="section-title">JOIN THE PAIDUP COMMUNITY</h6>
                <form>
                  <input className="email" type="" name="" />
                  <input className="btn" type="submit" name="" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="block-angle">
          <div className="container">
            <div className="row footer-content">
              <div className="col-sm-12">
                <ul className="list-inline-center">
                  <li className="footer-logo">
                    <img src={require("./assets/img/logo-footer.svg")} />
                  </li>
                  <li>
                    <a href="mailto:info@paidup.io">
                      <img
                        className="social"
                        src={require("./assets/img/social/email.svg")}
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/paidupPH">
                      <img
                        className="social"
                        src={require("./assets/img/social/fb.svg")}
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/paidupph">
                      <img
                        className="social"
                        src={require("./assets/img/social/twitter.svg")}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
